import { Injectable } from "@angular/core";

import {
 IWebAuthnLoginRequest, IWebAuthnStartLoginResponse,
	KnoxApiService,
} from "@aex/security/shared";
import {WebAuthnService} from "./web-authn.service";
import {AuthService} from "@aex/shared/root-services";
import {SecurityLoginHandlerService} from "../../../../common/src/lib/services/security-login-handler.service";

@Injectable({
	providedIn: 'root',
})
export class KnoxLoginService {

	constructor(
		private readonly knoxApiService: KnoxApiService,
		private readonly webAuthnService: WebAuthnService,
		private readonly authService: AuthService,
		private readonly securityLoginHandlerService: SecurityLoginHandlerService
	) {
	}

	// Initiate WebAuthn Authentication/Login
	authenticateWebAuthn(loginId: string, returnUrl: string): void {
		// Step 1: Call the backend API to get the authentication request
		this.knoxApiService.startWebAuthnLogin(loginId)
			.subscribe((credentialRequest: IWebAuthnStartLoginResponse) => {
				// this.rpId = credentialRequest.options.rp_id;
				// Step 2: Call WebAuthn get method using the request data
				this.webAuthnService.startAuthentication(credentialRequest.options)
					.then( (webAuthnLoginRequest: IWebAuthnLoginRequest) => {
						// Step 3: Send the credential back to the backend for completion
						this.knoxApiService.completeWebAuthnLogin(webAuthnLoginRequest)
							.subscribe(loginResponse => {
								this.authService.processLoginResponse(
									loginId,
									loginResponse
								);
								this.securityLoginHandlerService.determineLoginResponse(
									loginResponse,
									loginId,
									returnUrl,
								);
							});
					})
					.catch(err => {
						console.error('Authentication failed', err);
					});
			});
	}

}
