import {
	ENVIRONMENT_CONFIG_TOKEN,
	IEnvironment,
	KEY_AUTH_TOKEN
} from '@aex/shared/common-lib';
import { AuthService, BaseConfigService } from '@aex/shared/root-services';
import {HttpClient} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {KnoxAuthApiService} from "../services/knox-auth-api.service";

@Injectable()
export class SecurityAuthService extends AuthService {

	constructor(
			http: HttpClient,
			router: Router,
			configService: BaseConfigService,
			@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
			protected readonly knoxAuthApiService: KnoxAuthApiService
	) {
		super(http, router, configService, environment);
	}

	public override loginProvider(): Observable<string> {
		if (this.environment.knoxEnabled)
		  return this.loginClientGrantCredentialAuthToken();
		else
			return super.loginProvider() ;
	}

	public override setAuthToken(value: string): void {
		super.setAuthToken(value);
		this.configService.store(KEY_AUTH_TOKEN, value);
	}

	protected override getToken(key: string): string {
		return this.configService.retrieve(key);
	}
}
