import { IPublicKeyCredentialCreationOptions, IPublicKeyCredentialDescriptor, IWebAuthnLoginRequest, IWebAuthnPublicKeyAuthenticationRequest, IWebAuthnRegisterCompleteRequest, IWebAuthnStartResponse } from '@aex/security/shared';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebAuthnService {

  constructor() { }

  // Start WebAuthn registration
  public startRegistration(
    credentialRequest: IPublicKeyCredentialCreationOptions,
    user_id: string
  ): Promise<IWebAuthnRegisterCompleteRequest> {
    const publicKey = {
      challenge: this.base64ToUint8Array(credentialRequest.challenge),
      rp: {
        name: credentialRequest.rp.name,
        id: credentialRequest.rp.id
      },
      user: {
        id: this.base64ToUint8Array(credentialRequest.user.id),
        name: credentialRequest.user.name,
        displayName: credentialRequest.user.display_name
      },
      pubKeyCredParams: credentialRequest.pub_key_cred_params.map((param: any) => ({
        type: "public-key" as const,
        alg: param.alg
      })),
      authenticatorSelection: credentialRequest.authenticator_selection as AuthenticatorSelectionCriteria,
      timeout: credentialRequest.timeout,
      attestation: credentialRequest.attestation
    };

    return navigator.credentials.create({ publicKey }).then((credential) => {
      return this.buildWebAuthnRegisterCompleteRequest(
        credential,
        credentialRequest.rp.id,
        window.location.origin,
        user_id,
      );
    }).catch((err) => {
      console.error('WebAuthn registration failed', err);
      throw err;
    });
  }

  private buildWebAuthnRegisterCompleteRequest(
    publicKeyCredential: any,
    rp_id: string,
    origin: string,
    user_id: string
  ): IWebAuthnRegisterCompleteRequest {
    var response = publicKeyCredential.response;
    var webAuthnRegisterCompleteRequest: IWebAuthnRegisterCompleteRequest = {
      credential: {
        id: publicKeyCredential.id,
        raw_id: this.arrayBufferToBase64(publicKeyCredential.rawId),
        response: {
          attestation_object: this.base64UrlEncode(response.attestationObject),
          client_data_json: this.arrayBufferToBase64(response.clientDataJSON)
        },
        transports: response.getTransports(),
        client_extension_results: publicKeyCredential.getClientExtensionResults(),
        type: publicKeyCredential.type,
        authenticator_attachment: publicKeyCredential.authenticatorAttachment
      },
      origin: origin,
      rp_id: rp_id,
      user_id: user_id
    };
    return webAuthnRegisterCompleteRequest;
  }

  // Start WebAuthn authentication/login
  public startAuthentication(credentialRequest: IWebAuthnStartResponse): Promise<IWebAuthnLoginRequest> {
    const publicKey = {
      challenge: this.base64ToUint8Array(credentialRequest.challenge),
      allowCredentials: credentialRequest.allow_credentials.map(
        (cred: IPublicKeyCredentialDescriptor) => ({
          id: this.base64ToUint8Array(cred.id),
          type: 'public-key' as const,
          // transports: cred.transports
        }
      )),
      timeout: credentialRequest.timeout,
      userVerification: 'required' as const//credentialRequest.userVerification
    };

    return navigator.credentials.get({ publicKey }).then((credential) => {
        return this.buildWebAuthnLoginRequest(
          credential as PublicKeyCredential,
          window.location.origin,
          credentialRequest.rp_id,
          null
        );
    }).catch((err) => {
      console.error('WebAuthn authentication failed', err);
      throw err;
    });
  }

  private buildWebAuthnLoginRequest(
    credential: PublicKeyCredential,
    origin: string,
    rpId: string,
    twoFactorTrustId?: string
  ) : IWebAuthnLoginRequest {
    // Extract the credential ID (rawId) and convert it to base64
    const rawId = this.arrayBufferToBase64(credential.rawId);

    // Extract the authenticator data, client data JSON, and the signature from the assertion response
    const response = credential.response as AuthenticatorAssertionResponse;

    const authenticatorData = this.base64UrlEncode(response.authenticatorData);
    const clientDataJSON = this.base64UrlEncode(response.clientDataJSON);
    const signature = this.base64UrlEncode(response.signature);
    const userHandle = response.userHandle ? this.base64UrlEncode(response.userHandle) : null;

    // Build the WebAuthnPublicKeyAuthenticationRequest (credential)
    const credentialData: IWebAuthnPublicKeyAuthenticationRequest = {
      id: credential.id,
      type: credential.type,
      raw_id: rawId,
      response: {
        authenticator_data: authenticatorData,
        client_data_json: clientDataJSON,
        signature,
        user_handle: userHandle
      }
    };

    // Return the WebAuthnLoginRequest, including credential, origin, rpId, and optional twoFactorTrustId
    return {
      credential: credentialData,
      origin,                               // e.g., "https://example.com"
      rp_id: rpId,                          // e.g., "example.com"
      two_factor_trust_id: twoFactorTrustId // Optional: pass if needed
    };
  }

  base64ToUint8Array(base64String: string) : Uint8Array {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = atob(base64);
    const buffer = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }

    return buffer;
  }

  base64UrlEncode(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer)));
    return btoa(binary)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, ''); // Remove padding characters
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    // Process the array in chunks to avoid stack overflow with large buffers
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary); // Convert to Base64
  }

}
