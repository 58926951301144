import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";

import {
	IActionDto,
	IBoolResponse,
	ICloneRoleRequestDto,
	ICloneRoleResponseDto,
	IInterOrgUnitAccessWithDataDto,
  IOrgUnitAccessOrgUnitWithDataDto,
	IOrgUnitDto,
	IOrgUnitTypeDto,
	IPersonUserDto,
	IRoleActionInfoDto,
	IRoleDto,
  ISystemActions,
  IWebAuthnLoginRequest,
  IWebAuthnRegisterCompleteRequest,
  IWebAuthnRegisterCompleteResponse,
  IWebAuthnRegisterStartResponse,
  IWebAuthnStartLoginResponse,
} from '../interfaces/knox';

import {
	IMaintainInterOrgUnitAccessRoleDto,
	IMaintainOrgUnitAccessingOrgUnitDto,
	IMaintainOrgUnitOrgUnitDto,
	IMaintainOrgUnitTypeRoleDto,
	IMaintainPersonUserAccessingRoleDto,
	IMaintainPersonUserOrgUnitDto,
	IMaintainPersonUserRoleDto,
	IMaintainRoleDataDto,
	ISummaryOrgUnitTypeActionRequestDto,
	ISummaryRoleActionRequestDto,
	ISummaryUserActionRequestDto,
} from '../interfaces/knox-nested';

import { IPagedData } from "@aex/shared/common-lib";
import { KnoxApiBaseService } from './knox-api-base.service';
import { KnoxApiUrls, KnoxAuthApiUrls } from '../helpers/knox-constants';
import {ILoginResponseDto} from "../interfaces/knox-auth";

@Injectable({
	providedIn: 'root',
})
export class KnoxApiService extends KnoxApiBaseService {
	constructor(http: HttpClient) {
    super(http);
  }

	public getUserOrgUnits(userId: string): Observable<IOrgUnitDto[]> {
		return this.get<IOrgUnitDto[]>(`${KnoxApiUrls.Users}/${userId}/${KnoxApiUrls.OrgUnits}`);
	}

	public getOrgUnitUsersByUserId(userId: string): Observable<IMaintainPersonUserOrgUnitDto> {
		return this.get<IMaintainPersonUserOrgUnitDto>(`${KnoxApiUrls.Users}/${userId}/accessed-org-units`);
	}

	public saveMaintainUserOrgUnitData(userId: string, maintainUserOrgUnit: IMaintainPersonUserOrgUnitDto): Observable<IMaintainPersonUserOrgUnitDto> {
		return this.put<IMaintainPersonUserOrgUnitDto, IMaintainPersonUserOrgUnitDto>(
			`${KnoxApiUrls.Users}/${userId}/accessed-org-units`,
			maintainUserOrgUnit,
		);
	}

	public getOrgUnitRoles(orgUnitId: string): Observable<IRoleDto[]> {
		return this.get<IRoleDto[]>(`${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Roles}`);
	}

	public getOrgUnitCustomerRoles(orgUnitId: string): Observable<IRoleDto[]> {
		return this.get<IRoleDto[]>(`${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Roles}/customer`);
	}

	public getSystemRoles(): Observable<IRoleDto[]> {
		return this.get<IRoleDto[]>(`${KnoxApiUrls.SystemRoles}`);
	}

	public getRole(roleId: string): Observable<IRoleDto> {
		return this.get<IRoleDto>(`${KnoxApiUrls.Roles}/${roleId}`);
	}

	public insertRole(role: IRoleDto): Observable<IRoleDto> {
		const roles: IRoleDto[] = []
		roles.push(role);
		return this.insertRoles(roles).pipe(
				map(
						(savedRoles: IRoleDto[]) : IRoleDto => {
							if (savedRoles.length === 1)
								return savedRoles[0];
							else
								return null;
						},
				),
		);
	}

	public insertRoles(roles: IRoleDto[]): Observable<IRoleDto[]> {
		return this.post<IRoleDto[], IRoleDto[]>(`${KnoxApiUrls.Roles}`, roles);
	}

	public deleteRole(roleId: string): Observable<void> {
		return this.delete<void>(`${KnoxApiUrls.Roles}/${roleId}`);
	}

	public saveMaintainRoleData(
		roleId: string,
		maintain_data: IMaintainRoleDataDto,
	): Observable<IMaintainRoleDataDto> {
		return this.put<IMaintainRoleDataDto, IMaintainRoleDataDto>(
			`${KnoxApiUrls.Roles}/${roleId}/data`,
			maintain_data,
		);
	}

	public cloneRoleData(roleData: ICloneRoleRequestDto): Observable<ICloneRoleResponseDto> {
		return this.post<ICloneRoleRequestDto, ICloneRoleResponseDto>(`${KnoxApiUrls.Roles}/clone`, roleData);
	}

	public getRoleUserList(orgUnitId: string, roleId: string): Observable<IMaintainPersonUserAccessingRoleDto> {
		return this.get<IMaintainPersonUserAccessingRoleDto>(
			`${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Roles}/${roleId}/${KnoxApiUrls.Users}`,
		);
	}

	public saveMaintainRoleUserData(
		orgUnitId: string,
		roleId: string,
		maintain_data: IMaintainPersonUserAccessingRoleDto,
	): Observable<IMaintainPersonUserAccessingRoleDto> {
		return this.put<IMaintainPersonUserAccessingRoleDto, IMaintainPersonUserAccessingRoleDto >(
				`${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Roles}/${roleId}/${KnoxApiUrls.Users}`,
				maintain_data,
		);
	}

	public getActions(): Observable<IPagedData<IActionDto>> {
		return this.get<IPagedData<IActionDto>>(KnoxApiUrls.Actions);
	}

	//#todo change to use expand/reduce
	// https://stackoverflow.com/questions/44097231/rxjs-while-loop-for-pagination
	public getAllActions(): Observable<IActionDto[]> {
		return this.getActions().pipe(
				switchMap(
						(pagedData: IPagedData<IActionDto>)  =>{
							return of(pagedData.items);
						},
				),
		);
	}

	public getOrgUnitType(orgUnitTypeId: string): Observable<IOrgUnitTypeDto> {
		return this.get<IOrgUnitTypeDto>(`${KnoxApiUrls.OrgUnitTypes}/${orgUnitTypeId}`);
	}

	public getOrgUnitTypes(): Observable<IPagedData<IOrgUnitTypeDto>> {
		return this.get<IPagedData<IOrgUnitTypeDto>>(KnoxApiUrls.OrgUnitTypes);
	}

	public getAllOrgUnitTypes(): Observable<IOrgUnitTypeDto[]> {
		// Need to loop through get all pages
		return this.getOrgUnitTypes().pipe(
			map((data: IPagedData<IOrgUnitTypeDto>) => {
				return data.items;
			}),
		);
	}

	public getOrgUnitUsers(orgUnitId: string): Observable<IPersonUserDto[]> {
		return this.get<IPersonUserDto[]>(`${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Users}?managedUsers=true`);
	}

	public getPersonUser(userId: string): Observable<IPersonUserDto> {
		return this.get<IPersonUserDto>(`${KnoxApiUrls.Users}/${userId}`);
	}

	public updatePersonUser(userId: string, personUserDto: IPersonUserDto): Observable<IPersonUserDto> {
		return this.put<IPersonUserDto, IPersonUserDto>(`${KnoxApiUrls.Users}/${userId}`, personUserDto);
	}

	public getPersonUserByEmail(email: string): Observable<IPersonUserDto> {
		return this.get<IPersonUserDto>(`${KnoxApiUrls.Users}/by-email/${email}`);
	}

	public saveMaintainUserRoleData(
		userId: string,
		orgUnitId: string,
		role_data: IMaintainPersonUserRoleDto,
	): Observable<IMaintainPersonUserRoleDto> {
		return this.put<IMaintainPersonUserRoleDto, IMaintainPersonUserRoleDto>(
			`${KnoxApiUrls.Users}/${userId}/${KnoxApiUrls.OrgUnits}/${orgUnitId}/${KnoxApiUrls.Roles}/employee`, role_data);
	}

	public getOrgUnit(orgUnitId: string): Observable<IOrgUnitDto> {
		return this.get<IOrgUnitDto>(`${KnoxApiUrls.OrgUnits}/${orgUnitId}`);
	}

	public getAccessorOrgUnits(accessedOrgUnitId: string): Observable<IInterOrgUnitAccessWithDataDto[]> {
		return this.get<IInterOrgUnitAccessWithDataDto[]>(`${KnoxApiUrls.InterOrgUnitAccesses}/accessor-org-units/by-accessed-id/${accessedOrgUnitId}`);
	}

	public getAccessedOrgUnits(accessorOrgUnitId: string): Observable<IInterOrgUnitAccessWithDataDto[]> {
		return this.get<IInterOrgUnitAccessWithDataDto[]>(`${KnoxApiUrls.InterOrgUnitAccesses}/accessed-org-units/by-accessor-id/${accessorOrgUnitId}`);
	}

	public getOrgUnitsAccessingOrgUnit(accessedOrgUnitId: string): Observable<IMaintainOrgUnitAccessingOrgUnitDto> {
		return this.get<IMaintainOrgUnitAccessingOrgUnitDto>(`${KnoxApiUrls.OrgUnits}/${accessedOrgUnitId}/accessor-org-units`);
	}

	public saveMaintainOrgUnitAccessingOrgUnitRules(
		accessedOrgUnitId: string,
		maintainOrgUnitAccessingOrgUnitDto : IMaintainOrgUnitAccessingOrgUnitDto,
	): Observable<IMaintainOrgUnitAccessingOrgUnitDto> {
		return this.put<IMaintainOrgUnitAccessingOrgUnitDto, IMaintainOrgUnitAccessingOrgUnitDto>(
			`${KnoxApiUrls.OrgUnits}/${accessedOrgUnitId}/accessor-org-units`,
			maintainOrgUnitAccessingOrgUnitDto,
		);
	}

	public getInheritedOrgUnits(inheritorOrgUnitId: string): Observable<IOrgUnitAccessOrgUnitWithDataDto[]> {
		return this.get<IOrgUnitAccessOrgUnitWithDataDto[]>(`${KnoxApiUrls.OrgUnitInheritOrgUnits}/inherited-org-units/by-inheritor-id/${inheritorOrgUnitId}`);
	}
  public getMaintainInheritedOrgUnits(inheritorOrgUnitId: string): Observable<IMaintainOrgUnitOrgUnitDto> {
		return this.get<IMaintainOrgUnitOrgUnitDto>(`${KnoxApiUrls.OrgUnitInheritOrgUnits}/inherited-org-units/${inheritorOrgUnitId}`);
  }
	public saveMaintainOrgUnitAccessOrgUnitRules(
		inheritorOrgUnitId: string,
		maintainOrgUnitOrgUnitDto : IMaintainOrgUnitOrgUnitDto,
	): Observable<IMaintainOrgUnitOrgUnitDto> {
		return this.put<IMaintainOrgUnitOrgUnitDto, IMaintainOrgUnitOrgUnitDto>(
			`${KnoxApiUrls.OrgUnitInheritOrgUnits}/inherited-org-units/${inheritorOrgUnitId}`,
			maintainOrgUnitOrgUnitDto,
		);
	}

	public getOrgUnitsAccessedByOrgUnit(accessorOrgUnitId: string): Observable<IMaintainOrgUnitOrgUnitDto> {
		return this.get<IMaintainOrgUnitOrgUnitDto>(`${KnoxApiUrls.OrgUnits}/${accessorOrgUnitId}/accessed-org-units`);
	}

	public getOrgUnitTypeOrgUnits(orgUnitTypeId: string): Observable<IOrgUnitDto[]> {
		return this.get<IOrgUnitDto[]>(`${KnoxApiUrls.OrgUnits}/${KnoxApiUrls.OrgUnitTypes}/${orgUnitTypeId}`);
	}

	public saveMaintainOrgUnitOrgUnitData(
		accessorOrgUnitId : string,
		maintainInterOrgUnitAccessorDto : IMaintainOrgUnitOrgUnitDto,
	) : Observable<IMaintainOrgUnitOrgUnitDto> {
		return this.put<IMaintainOrgUnitOrgUnitDto, IMaintainOrgUnitOrgUnitDto>(
			`${KnoxApiUrls.OrgUnits}/${accessorOrgUnitId}/accessed-org-units`,
			maintainInterOrgUnitAccessorDto,
		);
	}

	public saveMaintainInterOrgUnitAccessRole(
			interOrgUnitAccessId: string,
			maintainInterOrgUnitAccessRoleDto: IMaintainInterOrgUnitAccessRoleDto,
	): Observable<IMaintainInterOrgUnitAccessRoleDto>
	{
		return this.put<IMaintainInterOrgUnitAccessRoleDto, IMaintainInterOrgUnitAccessRoleDto>(
			`${KnoxApiUrls.InterOrgUnitAccesses}/${interOrgUnitAccessId}/${KnoxApiUrls.Roles}`,
			maintainInterOrgUnitAccessRoleDto,
		);
	}

	public getManageCompanyRoleData(roleId: string) : Observable<IMaintainRoleDataDto> {
		return this.get<IMaintainRoleDataDto>(`${KnoxApiUrls.Roles}/${roleId}/role-actions`)
	}

	public getManageCompanyCustomerRoleData(roleId: string) : Observable<IMaintainRoleDataDto> {
		return this.get<IMaintainRoleDataDto>(`${KnoxApiUrls.Roles}/${roleId}/role-actions/customer`)
	}

	public getManageUserRoleData(userId: string, companyId: string) : Observable<IMaintainPersonUserRoleDto> {
		return this.get<IMaintainPersonUserRoleDto>(`${KnoxApiUrls.Users}/${userId}/${KnoxApiUrls.OrgUnits}/${companyId}/${KnoxApiUrls.Roles}/employee`)
	}

	public getCompanyTypeDataRoleData(companyTypeId: string) : Observable<IMaintainOrgUnitTypeRoleDto> {
		return this.get<IMaintainOrgUnitTypeRoleDto>(`${KnoxApiUrls.OrgUnitTypes}/${companyTypeId}/roles`)
	}

	public getInterOrgUnitRoleData(interOrgUnitAccessId: string) : Observable<IMaintainInterOrgUnitAccessRoleDto> {
		return this.get<IMaintainInterOrgUnitAccessRoleDto>(`${KnoxApiUrls.InterOrgUnitAccesses}/${interOrgUnitAccessId}/role-rules`)
	}

	public getRoleSummaryActions(request: ISummaryRoleActionRequestDto) : Observable<IRoleActionInfoDto[]> {
		return this.post<ISummaryRoleActionRequestDto, IRoleActionInfoDto[]>(
			`${ KnoxApiUrls.Roles }/summary-role-actions`,
			request
		);
	}

	public getCompanyTypeRoleSummaryActions(request: ISummaryOrgUnitTypeActionRequestDto) : Observable<IRoleActionInfoDto[]> {
		return this.post<ISummaryOrgUnitTypeActionRequestDto, IRoleActionInfoDto[]>(
			`${ KnoxApiUrls.Roles }/summary-org-unit-type-role-actions`,
			request
		);
	}

	public getUserRoleSummaryActions(request: ISummaryUserActionRequestDto) : Observable<IRoleActionInfoDto[]> {
		return this.post<ISummaryUserActionRequestDto, IRoleActionInfoDto[]>(
			`${ KnoxApiUrls.Roles }/summary-user-role-actions`,
			request
		);
	}

	public getInterOrgUnitRoleSummaryActions(request: IMaintainInterOrgUnitAccessRoleDto) : Observable<IRoleActionInfoDto[]>{
		return this.post<IMaintainInterOrgUnitAccessRoleDto, IRoleActionInfoDto[]>(
			`${ KnoxApiUrls.Roles }/summary-inter-org-unit-access-role-actions`,
			request
		);
	}

  public registerKnoxActions(systemAactions : ISystemActions) : Observable<IBoolResponse> {
    return this.post<ISystemActions, IBoolResponse>(
      `${KnoxApiUrls.SystemActions}/register-client-actions`,
      systemAactions
    )
  }

	public startWebAuthnRegistration(userLoginId: string): Observable<IWebAuthnRegisterStartResponse>{
			return this.get<IWebAuthnRegisterStartResponse>(`${ KnoxApiUrls.Users }/start-web-authn-registration/${ userLoginId }`);
  }

	public completeWebAuthnRegistration(request : IWebAuthnRegisterCompleteRequest): Observable<IWebAuthnRegisterCompleteResponse>{
			return this.post<IWebAuthnRegisterCompleteRequest, IWebAuthnRegisterCompleteResponse>(
        `${ KnoxApiUrls.Users }/complete-web-authn-registration`,
        request
      );
  }

	public startWebAuthnLogin(userLoginId: string): Observable<IWebAuthnStartLoginResponse>{
			return this.get<IWebAuthnStartLoginResponse>(`${ KnoxAuthApiUrls.Auth }/start-web-authn-login/${ userLoginId }`);
  }

	public completeWebAuthnLogin(request : IWebAuthnLoginRequest): Observable<ILoginResponseDto>{
			return this.post<IWebAuthnLoginRequest, ILoginResponseDto>(
        `${ KnoxAuthApiUrls.Auth }/complete-web-authn-login`,
        request
      );
  }

}
