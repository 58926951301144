import {Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {
	IDENTITY_PROVIDER_TYPES, IdentityProviderLoginResponseDto,
	IOAuthSettingsDto, KnoxAuthApiService,
} from "@aex/security/shared";
import {DOCUMENT} from "@angular/common";
import {Router} from "@angular/router";

declare var AppleID: any;

@Injectable({
	providedIn: 'root',
})
export class KnoxSocialLoginService {

	public readonly APPLE_AUTH_JS_URL =  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
	public readonly APPLE_REDIRECT_URL =  "auth/apple/callback";

	/** Reference to render-instance */
	private readonly renderer: Renderer2;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly router: Router,
		private readonly knoxAuthApiService: KnoxAuthApiService,
		_renderer: RendererFactory2
	) {
		this.renderer = _renderer.createRenderer(null, null);
	}

	private loadScript(url: string): Promise<unknown> {
		return new Promise((resolve, reject) => {
			const script = this.renderer.createElement('script');
			script.type = 'text/javascript';
			script.src = url;
			script.text = ``;
			script.async = true;
			script.defer = true;
			script.onload = resolve;
			script.onerror = reject;
			this.renderer.appendChild(this.document.head, script);
		});
	}

	public loginWithGoogle(): void {
		this.knoxAuthApiService.initiateIdentityProviderLogin(
			IDENTITY_PROVIDER_TYPES.Google,
			`${window.location.origin}/auth/google/callback`,
		).subscribe((response: IdentityProviderLoginResponseDto) => {
			window.location.href = response.redirect_url;
		});
	}

	// https://javascript.plainenglish.io/sign-in-with-apple-in-angular-web-app-630a9791e291
	public loginWithApple(): void {
		/* NOTE: Need to add the following to index.html
	  <meta name="appleid-signin-client-id" content="[CLIENT_ID]">
	  <meta name="appleid-signin-scope" content="[SCOPES]">
	  <meta name="appleid-signin-redirect-uri" content="[REDIRECT_URI]">
	  <meta name="appleid-signin-state" content="[STATE]">
	  <meta name="appleid-signin-nonce" content="[NONCE]">
	  <meta name="appleid-signin-use-popup" content="true">
		 */
		this.loadScript(this.APPLE_AUTH_JS_URL).then(
			()=>{
				const redirectUri =`${window.location.origin}/${this.APPLE_REDIRECT_URL}`;
				this.knoxAuthApiService.getIdentityProviderSettings(
					IDENTITY_PROVIDER_TYPES.Apple,
					redirectUri,
				).subscribe((settingsDto: IOAuthSettingsDto) => {
					// window.location.href = response.redirect_url;
					this.signInWithApple(settingsDto, redirectUri).then(()=>{});
				});
			}
		)
	}

	private async signInWithApple(settingsDto: IOAuthSettingsDto, redirectUri: string) {

		AppleID.auth.init({
			clientId: settingsDto.client_id,
			scope: settingsDto.scope,
			redirectURI: redirectUri, //settingsDto.redirect_uri,
			secret: settingsDto.client_secret
		});

		const signInComplete = await AppleID.auth.signIn();
		// redirect to redirect with sign-in complete values

		if (signInComplete) {
			this.router.navigateByUrl(
				`${this.APPLE_REDIRECT_URL}?redirect_uri=${redirectUri}&code=${signInComplete.authorization.code}&identity_token=${signInComplete.authorization.id_token}`,
			);
		}
	}

}
