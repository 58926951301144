import { HttpErrorResponseExt } from '@aex/ngx-toolbox';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class HttpErrorService {

	constructor(
			private readonly toast: ToastrService,
	) { }

	private static getBadRequestErrors(error: IServerError | string): string {
		const lError: IServerError = typeof error === 'string' ? JSON.parse(error) : error;
		const errorList = lError.full_errors;
		return errorList && Array.isArray(errorList) ? errorList[0] : lError.error;
	}

	private static getErrorMsg(error: HttpErrorResponse, namespace: string): string {
		const messages: { [category: string]: { [code: number]: string | StringFunction } } = {
			default: {
				[HttpStatusCode.InternalServerError]: 'Server Error. Please try again.',
				[HttpStatusCode.GatewayTimeout]: 'Server timeout. Please try again.',
				[HttpStatusCode.Unauthorized]: null,
				[HttpStatusCode.NotFound]: null,
			},
			authentication: {
				[HttpStatusCode.BadRequest]: 'Could not complete the request. Please try again.',
				[HttpStatusCode.Unauthorized]: error.error?.is_locked_out ? HttpErrorService.getTimeRemaining(error.error.lockout_reset_date) : 'Incorrect Username or Password.',
				[HttpStatusCode.NotFound]: 'User not found.',
				[HttpStatusCode.GatewayTimeout]: 'Server timeout. Please try again.',
			},
			premise: {
				[HttpStatusCode.BadRequest]: serverMessage => serverMessage && serverMessage.includes('is not unique') // NOSONAR
						? 'This address already exists for another customer'
						: serverMessage,
				[HttpStatusCode.NotFound]: 'Premises not found.',
				[HttpStatusCode.InternalServerError]: null,
			},
			predefinePremiseStatus:{
				[HttpStatusCode.InternalServerError]: 'Server Error. Please try again.',
				[HttpStatusCode.Unauthorized]: 'Unauthorized',
			},
			services: {
				[HttpStatusCode.BadRequest]: 'This premises already has an existing service',
				[HttpStatusCode.NotFound]: 'Service not found.',
				[HttpStatusCode.Conflict]: 'This product already exists in this premise',
			},
			products: {
				[HttpStatusCode.NotFound]: null,
			},
			areas: {
				[HttpStatusCode.InternalServerError]: 'Unable to get your area info.',
				[HttpStatusCode.NotFound]: null,
			},
			assets: {
				[HttpStatusCode.NotFound]: null,
			},
			paymentDate: {
				[HttpStatusCode.NotFound]: null,
				[HttpStatusCode.Unauthorized]: 'Unauthorised',
				[HttpStatusCode.InternalServerError]: null,
				[HttpStatusCode.BadRequest]: null,
			},
			paymentInfo: {
				[HttpStatusCode.NotFound]: 'Payment details not found',
				[HttpStatusCode.Unauthorized]: 'Unauthorised',
			},
			paymentMedium: {
				[HttpStatusCode.NotFound]: null,
				[HttpStatusCode.Unauthorized]: 'Unauthorised',
			},
			paymentHistory: {
				[HttpStatusCode.NotFound]: 'No payment yet',
				[HttpStatusCode.Unauthorized]: 'Unauthorised',
			},
			serviceCancellation: {
				[HttpStatusCode.BadRequest]: 'Cancellation work order is already logged against this service',
			},
			banks: {
				[HttpStatusCode.NotFound]: 'Failed to fetch bank list',
			},
			purchaseOrder: {
				[HttpStatusCode.InternalServerError]: 'Failed to place purchase order',
			},
			bankDetails: {
				[HttpStatusCode.InternalServerError]: error.error?.detail && error.error?.detail.includes('Conflict') // NOSONAR
					? 'Accounts details already exists'
					: 'Failed to validated banking details',
			},
			activatePaymentMedium: {
				[HttpStatusCode.InternalServerError]: 'Failed to activate payment medium',
			},
			deletePaymentMedium: {
				[HttpStatusCode.InternalServerError]: 'Failed to delete payment medium',
			},
			updatePaymentMedium: {
				[HttpStatusCode.InternalServerError]: 'Failed to update payment medium',
			},
			createPaymentMedium: {
				[HttpStatusCode.InternalServerError]: 'Failed to create payment medium',
			},
			getPlanByService: {
				[HttpStatusCode.InternalServerError]: 'Failed to fetch purchase plan',
			},
			knox: {
				[HttpStatusCode.InternalServerError]: 'Knox error',
			},
		};
		return this.determineErrorMessage(error, namespace, messages);
	}

	private static determineErrorMessage(
		error: HttpErrorResponse,
		namespace: string,
		messages: { [category: string]: { [code: number]: string | StringFunction } },
	) {
		let message = messages[namespace] && messages[namespace][error.status];
		if (error.status === HttpStatusCode.BadRequest) {
			const serverError = HttpErrorService.getBadRequestErrors(error.error);
			message = typeof message === 'function' ? message(serverError) : serverError || message;
		}
		else if (message === messages['knox'][HttpStatusCode.InternalServerError])
			message = error.error?.detail;
		else
			message = message === undefined ? messages[DEFAULT_NAMESPACE][error.status] : message;

		return typeof message === 'function' ? message() : message;
	}

	public handleError(error: HttpErrorResponse, namespace: string | boolean): HttpErrorResponse {
		const lNamespace = typeof namespace === 'string' ? namespace : DEFAULT_NAMESPACE;
		const message = HttpErrorService.getErrorMsg(error, lNamespace);
		if (message !== undefined) {
			if (message)
				this.toast.warning(message);
			return new HttpErrorResponseExt(error, message, true);
		} else
			return error;
	}

	private static getTimeRemaining(resetDate: string): string {
		const total = new Date(resetDate).valueOf() - new Date().valueOf();
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);

		return `You have been locked out, Please try again in ${minutes} minutes and ${seconds} seconds...`;
	}
}

type StringFunction = (message?: string) => string;

const DEFAULT_NAMESPACE = 'default';

interface IServerError {
	error: string
	full_errors: string[]
}
