import { SECURITY_ROUTES } from '@aex/security/shared';
import { AuthService } from '@aex/shared/root-services';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { gearIcon } from "@progress/kendo-svg-icons";

@Component({
	selector: 'app-home-container',
	template: `
		<div class="flex flex-col h-screen">
			<header class="header">
				<div class="flex flex-row justify-between items-center">
					<div class="header-logo" (click)="onHeaderLogoClick()"></div>
					<div class="flex flex-row gap-6" >
						<button kendoButton [fillMode]="'clear'" [size]="'large'"
						        [svgIcon]="svgCogIcon"
						        title="User Profile"
						        (click)="userProfile()">
						</button>
						<button kendoButton themeColor="primary" [size]="'large'"
						        (click)="logOut()">
							Log Out
						</button>
					</div>
				</div>
			</header>
			<app-security-layout-container class="h-full overflow-y-auto" ></app-security-layout-container>
			<footer class="flex flex-col justify-center items-center">
				<small class="copyright">
					All rights reserved. Integrated Network Management System © Automation Exchange 2024
				</small>
			</footer>
		</div>
	`,
	styleUrls: ['./home-container.component.scss'],
})
export class HomeContainerComponent {
	title = 'admin-nexus';
	public readonly svgCogIcon = gearIcon;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
	) {}

	public userProfile(): void{
		this.router.navigate(['security', SECURITY_ROUTES.userProfile.name]).then();
	}

	public logOut(): void {
		this.authService.logout().subscribe();
		this.router.navigateByUrl(SECURITY_ROUTES.login.path).then();
	}

	public onHeaderLogoClick(): void {
		this.router.navigate([SECURITY_ROUTES.home.name]).then();
	}

}
