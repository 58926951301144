import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {RouteBreadcrumbItem} from "../../common-lib/src/helpers/breadcrumb";

@Injectable(
	{providedIn: "root"}
)
export class BreadcrumbService  {

	public breadcrumbsBehaviorSubject : BehaviorSubject<RouteBreadcrumbItem[]>  = new BehaviorSubject([]);

	public $breadcrumbs: Observable<RouteBreadcrumbItem[]> = this.breadcrumbsBehaviorSubject.asObservable();

	public resetRouteBreadcrumbItems(breadcrumbItems: RouteBreadcrumbItem[]): void  {
		this.breadcrumbsBehaviorSubject.next(breadcrumbItems);
	}


}
