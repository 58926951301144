import {
	SecurityCommonModule,
} from '@aex/security/common';
import {
	APP_INITIALIZER,
	ErrorHandler,
	NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import {
	KnoxPermissionDirective, SecurityAuthInterceptor,
	// SecurityAuthInterceptor,
	SecurityAuthService,
	SecurityCompanyService,
	SecurityErrorInterceptor,
} from '@aex/security/shared';

import { AexNgToolboxModule, DEFAULT_LOADER_CONFIG, MockModule, NavigationService, OnlineOfflineService, TOASTER_CONFIG } from '@aex/ngx-toolbox';
import { ENVIRONMENT_CONFIG_TOKEN, GlobalErrorHandler } from '@aex/shared/common-lib';
import { AuthService, BaseConfigService } from '@aex/shared/root-services';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastContainerModule, ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { IAdminNexusEnvironment } from '../environments/environment-type';
import { NEXUS_BASE_ROUTES } from './app-routing.module';
import { AuthContainerComponent } from './main/auth-container/auth-container.component';
import { HomeContainerComponent } from './main/home-container/home-container.component';
import { AdminNexusConfigLoaderService } from './tools/services/admin-nexus-config-loader.service';
import { AdminNexusConfigService } from './tools/services/admin-nexus-config.service';

// import { MatIconModule } from '@angular/material/icon';
// import { MatInputModule } from '@angular/material/input';
// import { MatSelectModule } from '@angular/material/select';
// import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ButtonModule} from "@progress/kendo-angular-buttons";

export const ENV_CONFIG: Partial<IAdminNexusEnvironment> = environment;

@NgModule({
	declarations: [
		AppComponent,
		AuthContainerComponent,
		HomeContainerComponent,
		KnoxPermissionDirective,
	],
	imports: [
		RouterModule.forRoot(NEXUS_BASE_ROUTES),
		HttpClientModule,
		BrowserModule,
		CommonModule,
		SecurityCommonModule,
		BrowserAnimationsModule,
		MockModule.forRoot({
			enabled: environment.mocksEnabled,
			serverUrls: [
				ENV_CONFIG.knoxUrl, // Double check
			],
		}),
		NgxUiLoaderModule.forRoot(DEFAULT_LOADER_CONFIG),
		ToastContainerModule,
		ToastrModule.forRoot(TOASTER_CONFIG),
		AexNgToolboxModule.forRoot({httpTimeout: environment.httpTimeout}),
		FormsModule,
		RouterModule,
		// MatButtonModule,
		// MatInputModule,
		// MatIconModule,
		// MatSelectModule,
		FontAwesomeModule,
		ButtonModule,
	],
	providers: [
		{
			provide: AuthService,
			useClass: SecurityAuthService,
		},
		NavigationService,
		{
			provide: ENVIRONMENT_CONFIG_TOKEN,
			useValue: ENV_CONFIG,
		},
		{
			provide: BaseConfigService,
			useClass: AdminNexusConfigService,
		},
		AdminNexusConfigLoaderService,
		{
			provide: APP_INITIALIZER,
			deps: [AdminNexusConfigLoaderService],
			useFactory: (startUp: AdminNexusConfigLoaderService) => (): Promise<void> => startUp.loadAppConfig().toPromise(),
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SecurityAuthInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: SecurityErrorInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		OnlineOfflineService,
		ToastrService,
		SecurityCompanyService,
		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always' , appearance: 'outline'}},
	],
	bootstrap: [
		AppComponent,
	],
})
export class AppModule {
	constructor() { }
}
