import { HttpClient, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApiBaseService } from "./api-base.service ";
import { PredefinePremisesStatusApi } from "@aex/shared/apis";
import { IPagedData, IPredefinedPremiseStatus } from "@aex/shared/common-lib";
import { switchMap } from "rxjs/operators";

const PREDEFINED_PREMISE_STATUS_GENERAL_ERROR_TEXT = 'predefinePremiseStatus';

@Injectable({
	providedIn: 'root',
})
export class PredefinePremisesStatusApiService extends ApiBaseService {

	constructor(http: HttpClient) {
    super(http);
  }

	protected override getHandlerErrorText(): string {
		return PREDEFINED_PREMISE_STATUS_GENERAL_ERROR_TEXT;
	}

	// Get predefined premises status list
  public getPredefinedPremisesStatusList(): Observable<IPagedData<IPredefinedPremiseStatus>> {
    return this.get<IPagedData<IPredefinedPremiseStatus>>(`${PredefinePremisesStatusApi.predefinePremisesStatuses()}?count=all`);
  }

  // Get predefined premises status record by Id
  public getPredefinedPremisesStatusById(id: number): Observable<IPredefinedPremiseStatus> {
    return this.get<IPredefinedPremiseStatus>(PredefinePremisesStatusApi.predefinePremisesStatusesById(id));
  }

  // Save predefined premises status record
  public createPredefinedPremisesStatus(predefinedPremiseStatus: IPredefinedPremiseStatus): Observable<IPredefinedPremiseStatus> {
    const request = {
      predefined_premise_status: predefinedPremiseStatus,
    }
    return this.postWithHttpResponse<{ predefined_premise_status: IPredefinedPremiseStatus }, IPredefinedPremiseStatus>(
	    PredefinePremisesStatusApi.predefinePremisesStatuses(),
	    request,
    ).pipe(
			switchMap(
				(response : HttpResponse<IPredefinedPremiseStatus> ): Observable<IPredefinedPremiseStatus> => {
					if (response.status === HttpStatusCode.Created)
  						return this.get<IPredefinedPremiseStatus>(response.headers.get('location'));

					return of(null);
				},
			),
    );
  }

  // Update predefined premises status record
  public updatePredefinedPremisesStatus(id: number, predefinedPremiseStatus: IPredefinedPremiseStatus): Observable<IPredefinedPremiseStatus> {
     const request = {
      predefined_premise_status: predefinedPremiseStatus,
    }
    return this.put<{ predefined_premise_status: IPredefinedPremiseStatus }, IPredefinedPremiseStatus>(
	    PredefinePremisesStatusApi.predefinePremisesStatusesById(id),
	    request,
    );
  }

  public deletePredefinedPremisesStatus(id: number): Observable<void> {
    return this.delete<void>(PredefinePremisesStatusApi.predefinePremisesStatusesById(id));
  }

}
