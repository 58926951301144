import { Routes } from '@angular/router';
import { AuthContainerComponent } from './main/auth-container/auth-container.component';
import { HomeContainerComponent } from './main/home-container/home-container.component';
import { CanDeactivateGuard } from '@aex/ngx-toolbox';
import {AuthGuard, SECURITY_ROUTES} from '@aex/shared/common-lib';
import { GetUserOrgUnitsResolver } from '@aex/security/shared';
import { SecurityDashboardComponent } from "@aex/security/common";

export const NEXUS_BASE_ROUTES: Routes = [
	{
		path: '',
		redirectTo: `/${SECURITY_ROUTES.securityDashboard.name}`,
		pathMatch: 'full',
	},
	{
		path: '',
		component: AuthContainerComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('@aex/security/authentication').then(
						(m) => m.SecurityAuthenticationModule,
					),
			},
		],
	},
	{
		path: '',
		component: HomeContainerComponent,
		canDeactivate: [CanDeactivateGuard],
		canActivate: [AuthGuard],
		children: [
			{
				path: SECURITY_ROUTES.securityDashboard.name,
				component: SecurityDashboardComponent,
				resolve: {
					companies: GetUserOrgUnitsResolver,
				},
			},
			{
				path: SECURITY_ROUTES.security.name,
				loadChildren: () =>
					import('@aex/security/system').then((m) => m.SecuritySystemModule),
			},
		],
	},
];
