import { GetUserOrgUnitsResolver } from '@aex/security/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { ComponentHeaderComponent } from './components/component-header/component-header.component';
import { DataGridViewerComponent } from './components/data-grid-viewer/data-grid-viewer.component';
import { ManageActionsComponent } from './components/manage-actions/manage-actions.component';
import { ManageOrgUnitsComponent } from './components/manage-org-units/manage-org-units.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { RoleComponent } from './components/role/role.component';
import { SelectionTableComponent } from './components/selection-table/selection-table.component';
import { SummaryActionDataGridComponent } from './components/summary-action-data-grid/summary-action-data-grid.component';
import { UserComponent } from './components/user/user.component';
import { SecurityDialogService } from './services/security-dialog.service';
import { SelectionRoleTableComponent } from './components/selection-table/selection-role-table.component';
import { SelectionOrgUnitTableComponent } from './components/selection-table/selection-org-unit-table.component';
import { ConfigureTwoFactorAuthComponent } from './components/configure-two-factor-auth/configure-two-factor-auth.component';
import { EnableTwoFactorAuthenticationMethodComponent } from './components/enable-two-factor-authentication-method/enable-two-factor-authentication-method.component';
import { QRCodeModule} from "angularx-qrcode";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AexNgToolboxModule} from "@aex/ngx-toolbox";
// import { MatCardModule} from "@angular/material/card";
// import { MatButtonModule } from '@angular/material/button';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatInputModule } from '@angular/material/input';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatSelectModule} from "@angular/material/select";
// import { MatCheckboxModule} from "@angular/material/checkbox";
import { RegisterWebAuthnComponent} from "./components/register-web-authn/register-web-authn.component";
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SecurityLoginHandlerService} from "./services/security-login-handler.service";
import { SecurityDashboardComponent} from "./components/security-dashboard/security-dashboard.component";
import {
  BodyModule,
  GridModule,
  PagerModule,
  RowFilterModule,
  SharedFilterModule,
  SharedModule
} from "@progress/kendo-angular-grid";
import { ButtonModule } from "@progress/kendo-angular-buttons";
import {
  SwitchModule,
  TextBoxModule
} from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { SecurityLayoutContainerComponent } from "./components/security-layout-container/security-layout-container.component";
import {BreadCrumbModule} from "@progress/kendo-angular-navigation";
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import {TooltipModule} from "@progress/kendo-angular-tooltip";
import {DialogModule} from "@progress/kendo-angular-dialog";

@NgModule({
	declarations: [
		LoginFormComponent,
		ComponentHeaderComponent,
		DataGridViewerComponent,
		ManageActionsComponent,
		ManageRolesComponent,
		SelectionTableComponent,
		SelectionRoleTableComponent,
		RoleComponent,
		ManageUsersComponent,
		UserComponent,
		ManageOrgUnitsComponent,
		SummaryActionDataGridComponent,
		SelectionOrgUnitTableComponent,
		ConfigureTwoFactorAuthComponent,
		EnableTwoFactorAuthenticationMethodComponent,
    ChangePasswordComponent,
		RegisterWebAuthnComponent,
		SecurityDashboardComponent,
		SecurityLayoutContainerComponent,
    BreadcrumbComponent,
	],
	exports: [
		LoginFormComponent,
		ComponentHeaderComponent,
		DataGridViewerComponent,
		ManageActionsComponent,
		ManageRolesComponent,
		SelectionTableComponent,
		RoleComponent,
		ManageUsersComponent,
		UserComponent,
		ManageOrgUnitsComponent,
		SummaryActionDataGridComponent,
		ConfigureTwoFactorAuthComponent,
		EnableTwoFactorAuthenticationMethodComponent,
		ChangePasswordComponent,
		RegisterWebAuthnComponent,
    LoginFormComponent,
		SecurityDashboardComponent,
		SecurityLayoutContainerComponent,
    BreadcrumbComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		TableModule,
		FontAwesomeModule,

		// MatInputModule,
		// MatSlideToggleModule,
		// MatButtonModule,
		// MatTooltipModule,
		// MatDialogModule,
		// MatSelectModule,
		// MatCheckboxModule,
		// MatCardModule,

		QRCodeModule,

		AexNgToolboxModule.forChild(),
		BodyModule,
		ButtonModule,
		GridModule,
		PagerModule,
		RowFilterModule,
		SharedFilterModule,
		SharedModule,
		SwitchModule,
		TextBoxModule,
		LabelModule,
		BreadCrumbModule,
		TooltipModule,
		DialogModule
	],
	providers: [GetUserOrgUnitsResolver, SecurityDialogService, SecurityLoginHandlerService],
})
export class SecurityCommonModule {}
