import { Primitive } from '@aex/ngx-toolbox';

export enum AuthType {
	USER,
	NONE,
	PROXIED,
}

export enum ProductStatus {
	ACTIVE = 'Active',
}
export enum Count {
	Installs = 100,
	All = 'all',
}
export enum Urls {
	Medium = 'medium',
	Card = 'card',
	Installs = 'installs',
	Repairs = 'repairs',
	Mediums = 'mediums',
	Count = 100,
	InstallsFilter = 'installs-filter',
	WorkOrders = 'work-orders',
	Statuses = 'statuses',
	Types = 'types',
	History = 'history',
	Account = 'account',
	Notifications = 'notifications',
	Providers = 'providers',
	Products = 'products',
	Network = 'network',
	Status = 'status',
	NOC = 'noc',
	Proxy = 'proxy',
	Devices = 'devices',
	ServiceSatus = 'service-status',
	NMS = 'nms',
	Networks = 'networks',
	Assets = 'assets',
	InterestQuestions = 'interestquestions',
	Location = 'location',
	Availability = 'availability',
	Nodes = 'Nodes',
	Triage = 'triage',
	Configurations = 'configurations',
	Instances = 'instances',
	Snapshots = 'snapshots',
	ExternalId = 'externalId',
	Banks = 'banks',
	Payments = 'payments',
	CreditCard = 'credit-card',
	PreOrders = 'pre-orders',
	Relocations = 'relocations',
	Plans = 'plans',
	PurchasePlan = 'purchase-plan',
	PaymentMediums = 'payment-mediums',
	BankDetails = 'bank-details',
	CreditCardDetails = 'credit-card-details',
	PurchaseOrder = 'purchase-order',
	CreateBuyNowPurchaseOrder = 'buy-now',
	SubmitPurchaseOrderForCollection = 'submit-for-collection',
	ServiceDetailsAndActions = 'details-and-actions',
	Usage = 'usage',
	Service = 'service',
	Clone = 'clone',
	PaymentHistory = 'payment-history',
	PlanMediumUpdate = 'plan-medium-update',
	SetDebitDay = 'debit-day',
	ActivePaymentMediumForService = 'active-payment-medium-for-service',
	PaymentLinkMediumDetails = 'payment-link-medium-details',
	CreatePaymentMedium = 'create-payment-medium',
	CreateStripePaymentMedium = 'stripe-payment-medium',
	PaymentMedium = 'payment-medium',
	CardPayments = 'card-payments',
	Checkout = 'checkout',
	MediumDetails = 'medium-details',
	Update = 'update',
	IdentificationTypes = 'identification-types',
	Files = 'files',
	Roles = 'roles',
	Jobs = ' jobs',
	Export = 'export',
	UpdatePaymentMedium = 'update-payment-medium',
	Customers = 'customers',
	Password = 'password',
	Reset = 'reset',
	Config = 'config',
	Full = 'full',
	Installers = 'installers',
	Premises = 'premises',
	StripePayments = 'stripe-payments',
	CreateSetupIntent = 'create-setup-intent',
	Invoice = 'invoice',
	Services = 'services',
	GlobalSearch = 'global-search',
	Areas = 'areas',
	ClientAppPasswordResetLink = 'clientAppPasswordResetLink',
	ClientAppPasswordReset = 'clientAppPasswordReset',
	ClientAppConfirmEmail = 'clientAppConfirmEmail',
	ClientAppConfirmationLink = 'clientAppConfirmationLink',
	core = 'core',
	auth = 'auth',
	retryPurchasePayment = 'retry-purchase-payment',
	StripePaymentMediumExists = 'stripe-payment-medium-exists',
	Fault = 'fault',
	Faults = 'faults',
	Search = 'search',
	StatusTransitions = 'status-transitions',
	ServicePriceOverrides = 'service-price-overrides',
	Configuration = 'configurations',
	NIDInstall = 'nid-installs',
	InsertList = 'insert-list',
	SystemsActions = 'systemsActions',
	Systems = 'systems',
	Companies = 'companies',
	CompanyPersons = 'companyPersons',
	CompanyPerson = 'company-person',
	Persons = 'persons',
	PersonAliases = 'person-aliases',
	PersonTypes = 'personTypes',
	RegistrationStatuses = 'registrationStatuses',
	RelationshipTypes = 'relationshipTypes',
	CompanyTypes = 'companyTypes',
	CompanyCompanyTypes = 'companyCompanyTypes',
	CreateWithPersonTypes = 'create-with-person-types',
	CompanyPersonPersonTypes = 'companyPersonPersonTypes',
	CompanyRelationshipTypes = 'companyRelationshipTypes',
	Set = 'set',
	Remove = 'remove',
	Import = 'import',
	Create = 'create',
	CompanyType = 'company-type',
	MaintainList = 'maintain-list',
	SecuredEntitiesTypes = 'securedEntityTypes',
	Actions = 'actions',
	OrgUnit = 'org-unit',
	OrgUnits = 'org-units',
	OrgType = 'org-unit-type',
	OrgUnitType = 'orgUnitType',
	User = 'user',
	Users = 'users',
	Unlink = 'unlink',
	Page = 'page',
	PageNo = 'pageNo',
	PageSize = 'pageSize',
	Auth = 'auth',
	CreateUser = 'create-user',
	DeleteUser = 'delete-user',
	UserExists = 'user-exists',
	Email = 'email',
	Login = 'login',
	ForgotPassword = 'forgot-password',
	CheckChangePassword = 'check-change-password',
	ChangePassword = 'change-password',
	ResetPassword = 'reset-password',
	MfaSecret = 'mfa-secret',
	EnableMfaAuthenticator = 'enable-mfa-authenticator',
	DisableMfaAuthenticator = 'disable-mfa-authenticator',
	StartMfa = 'start-mfa',
	CompleteMfa = 'complete-mfa',
	CompleteMfaLogin = 'complete-mfa-login',
	SetLockout = 'set-lockout',
	RemoveLockout = 'remove-lockout',
	SocialLogin = 'social-login',
	CreateEntityType = 'create-entity-type',
	CreateEntityTypePassword = 'create-entity-type-permission',
	RetrieveEntityType = 'retrieve-entity-type',
	RetrieveEntityTypes = 'retrieve-entity-types',
	DeleteEntityType = 'delete-entity-type',
	DeleteEntityTypePermission = 'delete-entity-type-permision',
	CreateEntity = 'create-entity',
	RetrieveEntity = 'retrieve-entity',
	DeleteEntity = 'delete-entity',
	CreateEntityGrant = 'create-entity-grant',
	RetrieveEntityGrant = 'retrieve-entity-grant',
	DeleteEntityGrant = 'delete-entity-grant',
	ClientCredentialsGrant = 'client-credentials-grant',
	Verify = 'verify',
	InterOrgUnitAccessRoles = 'interOrgUnitAccessRoles',
	InterOrgUnitAccesses = 'interOrgUnitAccesses',
	MainTable = 'maintain',
	AssessedOrgUnit = 'assessed-org-unit',
	AssessorOrgUnit = 'assessor-org-unit',
	AssociatedUserList = 'associated-user-list',
	UpdateExternal = 'update-external',
	DeleteExternal = 'delete-external',
	OrgUnitOrgUnitTypes = 'orgUnitOrgUnitTypes',
	InsertListExternal = 'insert-List-external',
	OrgUnitSecuredEntities = 'orgUnitSecuredEntities',
	OrgUnitTypes = 'orgUnitTypes',
	OrgUnitTypeRoles = 'orgUnitTypeRoles',
	OrgUnitUsers = 'orgUnitUsers',
	AddOrgUnitUsers = 'add-org-unit-users',
	RoleActions = 'RoleActions',
	SubRoles = 'sub-roles',
	RoleRoles = 'roleRoles',
	Security = 'security',
	SecuredEntities = 'securedEntities',
	SecuredEntityTypes = 'securedEntityTypes',
	UserPerformAction = 'user-perform-action',
	UserPerformActionList = 'user-perform-action-list',
	SystemActions = 'systemActions',
	Link = 'link',
	ListByOrgUnit = 'list-by-org-unit',
	PagedList = 'paged-list',
	AssignGuid = 'assignGuid',
	UserLogins = 'user-logins',
	MayAccess = 'may-access',
	UserRoles = 'userRoles',
	HasAccess = 'has-access',
	UserSecuredEntities = 'userSecuredEntities',
	Maintain = 'maintain',
	MaintainRoles = 'maintain-roles',
	ExternalList = 'external-list',
	List = 'list',
	External = 'external',
	PeachRedirect = 'peach-redirect',
	NotificationRequests = 'notificationRequests',
	PaymentLinkPurchase = 'payment-link-purchase',
	PurchaseOnly = 'purchase-only',
	PaymentLinkPayment = 'payment-link-payment',
	Sources = 'sources',
	Purchases = 'purchases',
	OffSystemPurchaseDetail = 'off-system-purchase-detail',
	Refunds = 'refunds',
	RefundDocument = 'refund-doc',
	PayFastPayments = 'payfast-payments',
	AvailablePorts = 'available-ports',
	PredefinedPremiseStatuses = 'predefined-premise-statuses',
	References = 'references',
	CancellationReasons = 'CancellationReasons',
	VerifyEmail = 'VerifyEmail'
}

export enum ServiceStatuses {
	PendingApplication = 'pending application',
	PendingISPApplication = 'pending isp application',
}

export enum ServiceStatus {
	NotSet = 0,
	ExpressionOfInterest = 1,
	Active = 2,
	PendingIspApplication = 3,
	PendingInstallation = 4,
	PendingIspActivation = 5,
	ActivationInProgress = 6,
	Expiring = 7,
	Expired = 8,
	CancellationInProgress = 9,
	Cancelled = 10,
	IspChanged = 11,
	IspChangePending = 12,
	ProductChanged = 13,
	ProductChangePending = 14,
	Rejected = 15,
	Suspended = 16,
}

export enum ServiceStatusCategory {
	PreActivationStatuses = 'PreActivationStatuses',
	ActiveStatuses = 'ActiveStatuses',
	CancelledStatuses = 'CancelledStatuses',
	NonAexPosManagedStatuses = 'NonAexPosManagedStatuses'
}

export enum BillingStatus {
	UpToDate = 'UpToDate',
	InArrears = 'InArrears',
	NotApplicable = 'NotApplicable',
}

export enum OrderType {
	SHOW_INTEREST,
	PRE_ORDER,
	ORDER,
	NONE,
	NID_Product,
}

export enum OrderStatus {
	PRE_ORDER = 4096,
	INSTALLATION = 1,
	NID_INSTALLATION = 13,
}

export enum ProductPriceType {
	MONTHLY = 'Monthly',
	ONCE_OFF = 'Once Off',
	DAY_TO_DAY = 'Day to Day',
}

export enum ProductLineType {
	Day = 1,
	Month = 2,
	Installation = 3,
	DayBundle = 6
}

export enum ProductCapType {
	CAPPED = 'capped',
	UNCAPPED = 'uncapped',
}

export enum ServiceType {
	Recurring = 'Recurring',
	DayBundle = 'DayBundle',
}

export enum FullServiceStatus {
	Supsended = 'Suspended',
	Cancelled = 'Cancelled',
	Active = 'Active',
}

export enum WorkOrderType {
	Installation = 1,
	ChangeService = 4,
	Cancellation = 8,
	ChangeServiceProvider = 256,
	Fault = 512,
	Repair = 1024,
}

export enum ProductType {
	BACKHAUL = 1,
	FNO,
	ISP,
}

export enum RejectionType {
	NOT_INTERESTED,
	NOT_AT_HOME,
}

export enum FileUploadStatus {
	PENDING = 'Pending',
	IN_PROGRESS = 'Uploading',
	UPLOADED = 'Upload Done',
	ERROR = 'Error',
}

export enum RICA_STATUSES {
	VALID = '31C19D97-2E97-4A92-B480-1283302202A4',
	INVALID = 'C0D1845D-5DFA-4554-9060-F63A468ACCAC',
	PENDING_UPLOAD = 'CDBED79E-B05E-4C45-BE79-9E66BDD2FDC5',
	PENDING_VALIDATION = '2E07547F-ED2F-4A23-8200-3E2EC1EF2209',
}

export enum INSTALL_STATUSES {
	PENDING_ORDER_CREATION = '76572847-2E86-4678-8282-CD6A1E7DB314',
	ORDER_VALIDATION = '81AE94FA-0DE4-4E58-916D-8FE7FE7D3707',
}

function getInstallationAvailableStatuses(): Primitive[] {
	const PENDING_ISP_APPLICATION = 3;
	const PENDING_INSTALLATION = 4;
	return [PENDING_ISP_APPLICATION, PENDING_INSTALLATION];
}
export const INSTALL_AVAILABLE_STATUSES = getInstallationAvailableStatuses();

export enum PaymentType {
	DebitOrder = 'DebitOrder',
	Card = 'Card',
	PaymentLink = 'PaymentLink',
	OffSystem = 'OffSystem',
	ProviderSelectedOpaque = 'ProviderSelectedOpaque',
}

export enum PaymentMediumType {
	// This type matches PaymentService MediumType. Must confirm similar enum use cases and update them accordingly
	// API returns values as string, so string values used, but number listed for context
	BankAccount = 'BankAccount', //1
	CreditCard = 'CreditCard', //2
	PaymentLink = 'PaymentLink', //3
	OffSystem = 'OffSystem', //4
	ProviderSelectedOpaque = 'ProviderSelectedOpaque', //5
}

export enum CardPaymentProvider {
	None = 'none',
	PeachPayments = 'peach',
	Stripe = 'stripe',
}

export enum MediumType {
	DebitOrder = 1,
	Card = 2,
	PaymentLink = 3,
	OffSystem = 4,
}

export enum PaymentProvider {
	PaySoft = 'PaySoft',
	SSB = 'SSB',
	PeachPayment = 'PeachPayment',
	StripePayment = 'StripePayment',
}

export enum PurchaseStatusCalculate {
	Fulfilled = 'Paid',
	Cancelled = 'Cancelled',
	AwaitingPaymentCreation = 'Pending Future Dated Payment',
	PaymentInProcess = 'Payment Submitted to Payment Provider',
	PaymentFailed = 'Customer Payment Failed',
	Refunded = 'Refunded',
}

export enum ConfigurationMappingType {
	Product = 'Product',
}

export enum ConfigurationMappingTypeNumber {
	Product = 1,
}

export enum ConfigurationType {
	Payments = 'Payments',
}

export enum ConfigurationTypeNumber {
	Payments = 1,
}

export enum AllowedDaysType {
	Any = 'Any',
	Some = 'Some',
}

export enum NotificationStatus {
	InProgress = 0,
	AllProvidersFailed = 1,
	OneProviderFailedOthersInProgress = 2,
	HandledByNotificationServiceClient = 3,
	AllProvidersSucceeded = 4,
	OneProviderFailedOthersStillInProgress = 5,
	SomeProvidersFailedSomeSucceededSomeStillInProgress = 6,
	Mixed = 7,
}

export enum OnActivationPurchaseCreatedFor {
	ForActivationDate = 'ForActivationDate',
	ForFirstCollectionDay = 'ForFirstCollectionDay',
}

export enum CollectionDayAdjustmentPreference {
	PreferLastDayOfMonthIfCollectionDayNotInMonthDayCount = 'PreferLastDayOfMonthIfCollectionDayNotInMonthDayCount',
	PreferFirstDayOfNextMonthIfCollectionDayNotInMonthDayCount = 'PreferFirstDayOfNextMonthIfCollectionDayNotInMonthDayCount',
}

export enum CollectionDayChangeFutureDatedPurchaseBehaviour {
	AdjustFutureDatedPurchaseWithProRata = 'AdjustFutureDatedPurchaseWithProRata',
	DoNotAdjustFutureDatedPurchaseWithProRata = 'DoNotAdjustFutureDatedPurchaseWithProRata',
}

export enum RefundReason {
	BillingError = 'Billing Error',
	ServiceIssue = 'Service Issue',
	Other = 'Other',
}
export enum Unit {
	Month = 1,
	Day = 3,
}
