<div class="auth-container">
	<main class="flex-1 flex flex-col justify-center overflow-hidden">
		<router-outlet></router-outlet>
	</main>

	<footer class="flex flex-col justify-center items-center gap-y-2">
		<small class="copyright">
			All rights reserved. Integrated Network Management System © Automation Exchange 2024
		</small>
	</footer>
</div>


<!--<div class="flex flex-col h-screen">-->
<!--&lt;!&ndash;	<header class="header">&ndash;&gt;-->
<!--&lt;!&ndash;		<div class="flex flex-row justify-between items-center">&ndash;&gt;-->
<!--&lt;!&ndash;			<div class="header-logo"></div>&ndash;&gt;-->
<!--&lt;!&ndash;		</div>&ndash;&gt;-->
<!--&lt;!&ndash;	</header>&ndash;&gt;-->
<!--	<main class="flex-1 overflow-hidden">-->
<!--			<div class="security-content">-->
<!--				-->
<!--			</div>-->
<!--	</main>-->

<!--</div>-->
