import {Component} from "@angular/core";

@Component({
	selector: 'app-security-layout-container',
	styleUrls: ['./security-layout-container.component.scss'],
	templateUrl: './security-layout-container.component.html',
})
export class SecurityLayoutContainerComponent {

}
