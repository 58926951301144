import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogResult, DialogService} from "@progress/kendo-angular-dialog";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class SecurityDialogService {

	constructor(private readonly dialogService: DialogService) {}

	public openYesNoDialog(
		title: string,
		message: string,
		_ = true,
	): Observable<boolean> {
		const dialogRef = this.dialogService.open({
			title: title,
			content: message,
			actions: [
				{ text: 'No' },
				{ text: 'Yes', themeColor: 'primary' }
			],
		});
		return dialogRef.result.pipe(
			map(
				(dialogResult: DialogResult) =>{
					const textValue = (dialogResult as any)['text'];
					if (textValue)
  					return textValue === 'Yes';
					return false;
				}
			)
		);

	}
}
