<div class="container-row dashboard-header-container">
	<h3 class="flex-1">Roles and Permissions</h3>
	<div class="action-button-container">
		<button  *ngIf="mayEnableSystemRoles" kendoButton
		         themeColor="primary" class="action-button"
		         (click)="navigateToUrl(systemRolesRoute)"
		>
			System Roles
		</button>
		<button  *ngIf="mayEnableCompanyTypeRoles"
		         themeColor="primary" class="action-button"
		         kendoButton  (click)="navigateToUrl(companyTypesRoute)"
		>
			Company Types
		</button>
	</div>
</div>

	<div *ngIf="companies?.length > 0" class="section-container">
		<h4>Company</h4>
		<div class="content-container">
			<kendo-formfield >
				<kendo-floatinglabel  text="Select a company" class="w-full">
					<kendo-dropdownlist
						[data]="companies"
						textField="org_unit_name"
						valueField="external_org_unit_id"
						[(ngModel)]="selectedCompany"
						(selectionChange)="onSelectedCompanyChange($event)"
					>
					</kendo-dropdownlist>
				</kendo-floatinglabel>
			</kendo-formfield>
			<div class="action-button-container dashboard-action-button-container">
				<button  *ngIf="mayEnableRoles"  kendoButton
				         (click)="navigateToUrl(companyRolesRoute)"
				         [disabled]="!isCompanySelected" >
					Roles
				</button>
				<button  *ngIf="mayEnableCustomerRoles" kendoButton
				         (click)="navigateToUrl(companyCustomerRolesRoute)"
				         [disabled]="!isCompanySelected" >
					Customer Roles
				</button>

				<button  *ngIf="mayEnableUsers"  kendoButton
				         [disabled]="!isCompanySelected" (click)="navigateToUrl(userRoute)">
					Users
				</button>

				<button  *ngIf="mayEnableCompanyAssociations"  kendoButton
				         [disabled]="!isCompanySelected" (click)="navigateToUrl(companyAssociationRoute)">
					Company Associations
				</button>
				<button  *ngIf="mayEnableCompanyInheritedAccess"  kendoButton
				         [disabled]="!isCompanySelected"  (click)="navigateToUrl(companyInheritedAccessRoute)"
				>
					Company Inherited Access
				</button>
			</div>
		</div>
	</div>

	<div class="section-container" *ngIf="companies?.length === 0">
		Sorry, you do not have access to any of the companies. Please contact the support to gain access.
	</div>

